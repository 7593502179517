<template>
  <main>
    <Modal :modalVisible="isUnauthorized" id="relogin-modal" :closeIconVisible="false">
      <template slot="modal-content">
        <div v-if="isProcessing" class="w-full py-40">
          <vue-simple-spinner size="large" message="Please, wait..."></vue-simple-spinner>
        </div>
        <div v-else>
          <div v-if="isLoginSuccess">
            <AnimationSuccess message="Relogin Successful" />
          </div>
          <div class="flex flex-col gap-4" v-else>
            <h1 class="text-2xl font-semibold">Please relogin</h1>
            <TextField
              label="Email"
              type="text"
              placeholder="Enter your email address"
              v-model="email"
              :enterKeyAction="relogin"
              borderEnabled
              :error="errorInput['email'].message"
              :isStayShow="errorInput['email'].visible"
            />
            <div>
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Password</label>
              <div class="relative">
                <TextField
                  :type="typeInput"
                  borderEnabled
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  v-model="password"
                  :enterKeyAction="relogin"
                  @input="userIsTyping()"
                  :error="errorInput['password'].message"
                  :isStayShow="errorInput['password'].visible"
                />
                <img
                  src="@/assets/images/open-eye.svg"
                  v-if="typeInput === 'password' && isTyping"
                  @click="showPassword(true)"
                  class="w-5 absolute right-4 cursor-pointer top-1/2 transform -translate-y-1/2"
                />
                <img
                  src="@/assets/images/close-eye.svg"
                  v-if="typeInput === 'text' && isTyping"
                  @click="showPassword(false)"
                  class="w-5 absolute right-4 cursor-pointer top-1/2 transform -translate-y-1/2"
                />
              </div>
            </div>

            <div class="bg-red-10 p-2 rounded-lg" v-if="notificationError">
              <p class="text-sm font-medium">{{ notificationError }}</p>
            </div>
            <div class="flex justify-center gap-4">
              <Button buttonText="Relogin" @action="relogin" size="full" :disabled="!isButtonLoginEnable" />
              <Button buttonText="Logout" @action="logoutAndRedirectToLoginPage" size="full" type="secondary" />
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <TheHeader />
    <TheSideBar :navItems="filteredMenu" />
    <div class="content pt-20 ml-64 bg-neutral-100">
      <div class="p-4">
        <div class="flex justify-center items-center" style="height: 83vh" v-if="is403">
          <div class="text-center">
            <div class="text-3.5xl font-bold mb-10">We’re sorry!</div>
            <img class="" src="@/assets/images/403.png" alt="403" />
            <div>
              You don’t have the permission to access this page.<br />
              Please contact the system administrator
            </div>
          </div>
        </div>
        <div v-else>
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>
    <TheFooter />
    <Loading v-if="pageLoading" />
  </main>
</template>
<script>
import nav from '@/_nav'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ '@/components/Illustration/Loading'),
    TheHeader: () => import('@/components/Header/TheHeader'),
    TheSideBar: () => import('@/components/SideBar/TheSideBar'),
    TheFooter: () => import('@/components/Footer/TheFooter'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    AnimationSuccess: () => import(/* webpackChunkName: "TextField" */ '@/components/Animation/Success')
  },
  data() {
    return {
      nav: nav.items,

      // HIDE SIDEBAR =>  'Registration',  'CRM', 'OMS', 'Talent', 'Human Resources'
      // sidebar is object from file /src/_nav.js

      internalMenu: ['Overview', 'Master', 'Student', 'Ecommerce'  ],
      externalMenu: ['Overview', 'LMS', 'CRM'],
      superAdminMenu: ['Settings'],
      filteredMenu: [],
      email: '',
      password: '',
      typeInput: 'password',
      isTyping: false,
      notificationError: '',
      errorInput: {
        email: {
          message: '',
          visible: false,
          correctly: false
        },
        password: {
          message: '',
          visible: false,
          correctly: false
        }
      },
      isProcessing: false,
      isLoginSuccess: false,
      notReloadPages: ['Create New Class', 'Edit Class', 'Create New Class Schedule', 'Edit Class Schedule', 'Copy Class Schedule'],
      componentVisible: true
    }
  },
  computed: {
    ...mapGetters('common', ['pageLoading', 'is403', 'isUnauthorized']),
    ...mapGetters('customer', ['customer']),
    selectedClient() {
      return localStorage.getItem('client')
    },
    selectedMenu() {
      return this.selectedClient == 1 ? this.internalMenu : this.selectedClient === 'null' ? this.superAdminMenu : this.externalMenu
    },
    isButtonLoginEnable() {
      return !this.errorInput['email'].visible && !this.errorInput['password'].visible
    },
    routePath() {
      return this.$route.meta.label
    },
    isReloadPage() {
      return this.notReloadPages.filter((path) => path === this.routePath).length === 0
    }
  },
  watch: {
    email(val) {
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (val === '') {
        this.errorInput['email'].visible = true
        this.errorInput['email'].message = 'Email cannot be empty!'
        return
      }
      if (!regex.test(val)) {
        this.errorInput['email'].visible = true
        this.errorInput['email'].message = 'Email format is not correct!'
        return
      }
      this.errorInput['email'].visible = false
      this.errorInput['email'].message = ''
    },
    password(val) {
      if (val === '') {
        this.errorInput['password'].visible = true
        this.errorInput['password'].message = 'Password cannot be empty!'
        return
      }
      this.errorInput['password'].visible = false
      this.errorInput['password'].message = ''
    }
  },
  mounted() {
    this.setupMenu()
  },
  methods: {
    ...mapActions('customer', ['LOGOUT', 'LOGIN']),
    ...mapActions('common', ['setUnauthorized']),
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    userIsTyping() {
      if (this.password.length > 0) {
        this.isTyping = true
        return
      }
      this.isTyping = false
    },
    relogin() {
      this.isProcessing = true
      this.notificationError = ''
      this.LOGIN({
        email: this.email,
        password: this.password
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.isLoginSuccess = true
            setTimeout(() => {
              this.isProcessing = false
            }, 2000)
            setTimeout(() => {
              this.setUnauthorized({ value: false })
            }, 2500)
            setTimeout(() => {
              if (this.isReloadPage) location.reload()
            }, 3000)
            VueCookies.set('token', res.data.data.token, null, null, '.g2academy.co')
            localStorage.setItem('id', res.data.data.id)
          } else if (res.data.code == 422) {
            const time = res.data.code === 200 ? 5000 : 1000
            setTimeout(() => {
              this.isProcessing = false
            }, time)
            this.notificationError = 'Invalid credential! Please fill in the correct email & password.'
          } else {
            const time = res.data.code === 200 ? 5000 : 1000
            setTimeout(() => {
              this.isProcessing = false
            }, time)
            this.notificationError = 'Your request is failed!'
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.isProcessing = false
          }, 1000)
          this.notificationError = 'Your request is failed!'
        })
    },
    logoutAndRedirectToLoginPage() {
      this.setUnauthorized({ value: false })
      this.LOGOUT().then(() => {
        this.$router.push('/pages/login')
      })
    },
    async setupMenu() {
      let customer = await this.customer?.customerRoles[0]?.roles?.forEach((e) => {
        if (e === 'LP_CURATION') {
          this.selectedMenu.push('Curation')
        }
      })
      this.selectedMenu.forEach((menu) => {
        let filteredItem = nav.filter((navItem) => {
          return navItem.name === menu
        })
        this.filteredMenu.push(filteredItem[0])
      })
    }
  }
}
</script>
