var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('Modal', {
    attrs: {
      "modalVisible": _vm.isUnauthorized,
      "id": "relogin-modal",
      "closeIconVisible": false
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_vm.isProcessing ? _c('div', {
    staticClass: "w-full py-40"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Please, wait..."
    }
  })], 1) : _c('div', [_vm.isLoginSuccess ? _c('div', [_c('AnimationSuccess', {
    attrs: {
      "message": "Relogin Successful"
    }
  })], 1) : _c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('h1', {
    staticClass: "text-2xl font-semibold"
  }, [_vm._v("Please relogin")]), _c('TextField', {
    attrs: {
      "label": "Email",
      "type": "text",
      "placeholder": "Enter your email address",
      "enterKeyAction": _vm.relogin,
      "borderEnabled": "",
      "error": _vm.errorInput['email'].message,
      "isStayShow": _vm.errorInput['email'].visible
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('div', [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Password")]), _c('div', {
    staticClass: "relative"
  }, [_c('TextField', {
    attrs: {
      "type": _vm.typeInput,
      "borderEnabled": "",
      "id": "password",
      "name": "password",
      "placeholder": "Enter your password",
      "enterKeyAction": _vm.relogin,
      "error": _vm.errorInput['password'].message,
      "isStayShow": _vm.errorInput['password'].visible
    },
    on: {
      "input": function input($event) {
        return _vm.userIsTyping();
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm.typeInput === 'password' && _vm.isTyping ? _c('img', {
    staticClass: "w-5 absolute right-4 cursor-pointer top-1/2 transform -translate-y-1/2",
    attrs: {
      "src": require("@/assets/images/open-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword(true);
      }
    }
  }) : _vm._e(), _vm.typeInput === 'text' && _vm.isTyping ? _c('img', {
    staticClass: "w-5 absolute right-4 cursor-pointer top-1/2 transform -translate-y-1/2",
    attrs: {
      "src": require("@/assets/images/close-eye.svg")
    },
    on: {
      "click": function click($event) {
        return _vm.showPassword(false);
      }
    }
  }) : _vm._e()], 1)]), _vm.notificationError ? _c('div', {
    staticClass: "bg-red-10 p-2 rounded-lg"
  }, [_c('p', {
    staticClass: "text-sm font-medium"
  }, [_vm._v(_vm._s(_vm.notificationError))])]) : _vm._e(), _c('div', {
    staticClass: "flex justify-center gap-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Relogin",
      "size": "full",
      "disabled": !_vm.isButtonLoginEnable
    },
    on: {
      "action": _vm.relogin
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Logout",
      "size": "full",
      "type": "secondary"
    },
    on: {
      "action": _vm.logoutAndRedirectToLoginPage
    }
  })], 1)], 1)])])], 2), _c('TheHeader'), _c('TheSideBar', {
    attrs: {
      "navItems": _vm.filteredMenu
    }
  }), _c('div', {
    staticClass: "content pt-20 ml-64 bg-neutral-100"
  }, [_c('div', {
    staticClass: "p-4"
  }, [_vm.is403 ? _c('div', {
    staticClass: "flex justify-center items-center",
    staticStyle: {
      "height": "83vh"
    }
  }, [_vm._m(0)]) : _c('div', [_c('router-view', {
    key: _vm.$route.fullPath
  })], 1)])]), _c('TheFooter'), _vm.pageLoading ? _c('Loading') : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "text-3.5xl font-bold mb-10"
  }, [_vm._v("We’re sorry!")]), _c('img', {
    attrs: {
      "src": require("@/assets/images/403.png"),
      "alt": "403"
    }
  }), _c('div', [_vm._v(" You don’t have the permission to access this page."), _c('br'), _vm._v(" Please contact the system administrator ")])]);
}]

export { render, staticRenderFns }